import { initialAuth } from './authReducer'
import { initialUI } from './uiReducer'
import { initialMe } from './meReducer'
import { initialPosses } from './possesReducer'
import { initialProfiles } from './profilesReducer'
import { initialInvites } from './invitesReducer'

const initialState = {
  ui: initialUI,
  auth: initialAuth,
  me: initialMe,
  posses: initialPosses,
  profiles: initialProfiles,
  invites: initialInvites,
}

export default initialState
